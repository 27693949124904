// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse, AxiosError } from "axios";
import { setupCache } from "axios-cache-adapter";
import { verifyTokenExpired } from "./jwt";
import { LocalStorageService } from "./LocalStorageService";

const CACHE_MINUTES = 5;
// Função para gerar uma chave de cache dinâmica
function generateCacheKey() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

// Configuração inicial do cache com uma chave dinâmica
let cacheKey = generateCacheKey();
const cache = setupCache({
  maxAge: CACHE_MINUTES * 60 * 1000, // Tempo máximo de vida do cache (15 minutos)
  exclude: { query: false },
  key: cacheKey, // Utiliza a chave dinâmica inicial
});

/**
 * Classe ApiService:
 *
 * Dispõe serviços de acesso a api do bigcupom.
 * @since 1.0.0
 */
class ServiceApi {
  /**
   * Responsável por gerir globalmente as respostas da api.
   * @param {AxiosResponse<any>} response objeto AxiosReponse
   * @returns {AxiosResponse<any>} response
   * retorna o mesmo AxiosResponse recebido como parâmetro
   */
  #handleResponse = (response) => {
    return response;
  };
  /**
   * Responsável por gerir globalmente os erros de acesso a api.
   * @param {AxiosError<any>} error objeto AxiosError
   * @returns {Error} error objeto contendo detalhes
   * do erro
   */
  #handleError = (error) => Promise.reject(error);

  constructor() {
    /**
     * Propriedade routes.
     * Contém os valores das rotas para api.
     * Toda vez em que for necessário criar
     * algum método na classe para chamar a api
     * não esquecer de registrar a rota aqui para
     * utilizá-la.
     */
    this.routes = {
      signin: "/api/admin/user/signin",
      signinCode: "/api/admin/administrator/sign-code",
      resendCode: "/api/admin/administrator/resend-code",
      remainingAttempsCode: "api/admin/administrator/remaining-attempts-code",
      me: "/api/admin/user/get-me",
      listUsers: "/api/admin/user/list-user-points",
      getUsersId: "/api/admin/user/get-user-points/{id}",
      validCpf: "/api/admin/user/validate-cpf/{id}",
      updateUsersId: "api/admin/user/update-user-points/{id}",
      removeUserById: "/api/admin/user/delete-user/{id}",
      updateUserStatusMission: "api/admin/user/update-status-mission/{id}",
      getSmsByUserId: "api/admin/user/get-sms/{id}",
      getBannerImages: "/api/admin/banner/get-all",
      newBannerImage: "api/admin/banner/new-banner",
      orderCategory: "/api/admin/category/sort",
      bannerActive: "/api/admin/banner/active/{id}",
      getBannerId: "/api/admin/banner/get-banner/{id}",
      updateBanner: "/api/admin/banner/update-banner/{id}",
      getCarouselImages: "/api/admin/carousel/get-carousel-images",
      newCarouselImage: "/api/admin/carousel/new-carousel-item",
      uploadImageCarousel: "/api/admin/carousel/upload-image",
      orderCarousel: "/api/admin/carousel/sort-images",
      carouselActive: "/api/admin/carousel/active/{id}",
      getCarouselId: "/api/admin/carousel/get-one-item/{id}",
      updateCarousel: "/api/admin/carousel/update-one-item/{id}",
      getPrize: "/api/admin/prize/get",
      PrizeById: "/api/admin/prize/get/{id}",
      getStores: "/api/admin/store/get-all",
      storeDelete: "/api/admin/store/delete-one/{id}",
      uploadImageStore: "/api/admin/store/upload-image",
      getStoreId: "/api/admin/store/find-one/{id}",
      updateStore: "/api/admin/store/update-one/{id}",
      newStore: "/api/admin/store/new-store",
      getStoresId: "/api/admin/store/find-one/{id}",
      getCoupons: "/api/admin/coupon/get-coupons",
      getCouponsId: "/api/admin/coupon/get-coupons/{id}",
      newCoupon: "/api/admin/coupon/new-coupon",
      updateCoupon: "/api/admin/coupon/update-coupons/{id}",
      getCategory: "/api/admin/category/get",
      getCategoryId: "/api/admin/category/get/{id}",
      newCategory: "/api/admin/category/create",
      updateCategory: "/api/admin/category/update/{id}",
      updatePrize: "/api/admin/prize/update/{id}",
      newPrize: "/api/admin/prize/save",
      storeActive: "/api/admin/store/active/{id}",
      couponActive: "/api/admin/coupon/active/{id}",
      prizeActive: "/api/admin/prize/active/{id}",
      getusersAdmin: "/api/admin/administrator/list-users",
      getusersAdminId: "/api/admin/administrator/get-user/{id}",
      updateuserAdminId: "/api/admin/administrator/update/{id}",
      newusersAdmin: "/api/admin/administrator/create",
      setPassword: "/api/admin/user/change-password",
      setadminUser: "/api/admin/user/update-me",
      setadminUserActive: "/api/admin/administrator/set-active/{id}",
      getallGroups: "/api/admin/group/get-all",
      getGroupId: "/api/admin/group/get/{id}",
      groupActive: "/api/admin/group/active/{id}",
      newGroup: "/api/admin/group/create",
      updateGroupById: "/api/admin/group/update/{id}",
      getallModules: "/api/admin/module/get-all",
      newModule: "/api/admin/module/create",
      updateModule: "/api/admin/module/update/{id}",
      activeModule: "/api/admin/module/active/{id}",
      getModuleId: "/api/admin/module/get-one/{id}",
      orderModules: "/api/admin/module/sort",
      getMenus: "/api/admin/category/sidebar",
      getGroupActives: "/api/admin/group/get-active",
      generateCoupon: "/api/v1/admin/coupon/generate-coupons",
      statusGenerateCoupons: "/api/v1/admin/coupon/all-generate-coupons",
      generateAllCoupon: "/api/v1/admin/coupon/get-pre-coupons",
      sendPreCoupon: "/api/v1/admin/coupon/generate-coupon/{id}",
      getPreCouponId: "/api/v1/admin/coupon/show-precoupon/{id}",
      uploadImageCoupon: "/api/admin/coupon/upload-image",
      deletePreCouponId: "/api/v1/admin/coupon/delete-pre-coupon/{id}",
      sendPreCouponRekuten: "/api/admin/coupon/add-coupom-rakuten",
      syncPreCouponRekuten: "/api/admin/coupon/add-pre-coupons-rakuten",
      getPreCouponRekuten: "/api/admin/coupon/get-all-pre-coupons-rakuten",
      activePreCouponRekuten:
        "/api/admin/coupon/active-pre-coupon-rakuten/{id}",
      getPreCouponByIdRekuten:
        "/api/admin/coupon/get-id-pre-coupom-rakuten/{id}",
      information: "/api/admin/dashboard/get-data",
      getAffiliates: "/api/admin/affiliate/get-all",
      newAffiliate: "/api/admin/affiliate/new-affiliate",
      updateAffiliate: "/api/admin/affiliate/update/{id}",
      affiliateActive: "/api/admin/affiliate/active/{id}",
      getRegisters: "/api/admin/reports/registers",
      getReportsLogin: "/api/admin/reports/login",
      uploadImageRewards: "/api/admin/reward/upload-image",
      newRewards: "/api/admin/reward/create",
      getRewards: "/api/admin/reward/get-all",
      rewardActive: "/api/admin/reward/active/{id}",
      getRewardId: "/api/admin/reward/get-one/{id}",
      getRewardsMinu: "/api/admin/reward/get-all-rewards-api",
      generateGiftCard: "/api/admin/reward/send-voucher-file",
      getGiftCard: "/api/admin/reward/get-vouchers",
      getGenerateGiftCardById: "/api/admin/reward/get-voucher/{id}",
      getStatusGenerateGiftCard: "/api/admin/reward/get-files-voucher",
      updateRewardId: "/api/admin/reward/update/{id}",
      getReportsReward: "/api/admin/reports/get-rewards",
      getReportsRewardData: "/api/admin/reports/rewards",
      getReportsAffiliatesLogin: "/api/admin/reports/affiliates-login",
      updatemissionId: "/api/admin/mission/update/{id}",
      newMission: "/api/admin/mission/create",
      getMissions: "/api/admin/mission/get-all",
      getMissionsDependecy: "/api/admin/mission/list-all",
      getOrderMissions: "/api/admin/mission/order-list",
      uploadImageMissions: "/api/admin/mission/upload-image",
      missionActive: "/api/admin/mission/active/{id}",
      orderMission: "/api/admin/mission/sort",
      replacement: "/api/admin/exchange/get-all",
      updateReplacementStatus: "/api/admin/exchange/update/{id}",
      getReplacementDetailsById: "/api/admin/exchange/get-details/{id}",
      getNotifications: "/api/admin/notifications/get-all",
      updateNotificationStatusById: "/api/admin/notifications/update/{id}",
      updateAllNotificationStatus: "/api/admin/notifications/update-all",
      listHotOffers: "api/admin/offers/list",
      createHotOffers: "api/admin/offers",
      updateHotOffers: "api/admin/offers/{id}",
      getPostback: "api/admin/postback/list",
      createPostback: "api/admin/postback/create",
      updatePostBack: "api/admin/postback/update/{id}",
      getSettings: "/api/admin/setting/get-settings",
      updateSetting: "api/admin/setting/generate",
      getLandingPage: "/api/admin/landingpage/get-all",
      newLandingPage: "/api/admin/landingpage/create",
      uploadImageLandingPage: "/api/admin/landingpage/upload-image",
      updateLandingPage: "/api/admin/landingpage/update/{id}",
      deleteLandingPage: "/api/admin/landingpage/delete/{id}",
      getLandingPageById: "/api/admin/landingpage/get-id/{id}",
      /* categoria review */
      newReviewCategory: "/api/admin/product/category/create",
      setReviewCategory: "/api/admin/product/category/update/{id}",
      deleteReviewCategory: "/api/admin/product/category/delete/{id}",
      deleteQuestionReviewCategory: "/api/admin/product/question/delete/{id}",
      deleteQuestionReviewCategoryId:
        "/api/admin/product/question/delete-questions",
      getReviewCategoryById: "/api/admin/product/category/get-one/{id}",
      getAllReviewCategory: "/api/admin/product/category/get-all",
      getAllReviewCategoryList: "/api/admin/product/category/list",
      getQuestionsByCategory:
        "/api/admin/product/question/get-questions-by-category/{id}",
      newQuestionsByCategory: "/api/admin/product/question/create-questions",
      setQuestionsByCategory: "/api/admin/product/question/update-questions",
      uploadImageCategory: "/api/admin/product/upload-image",

      /* Product review */
      findProductsMLList: "/api/admin/product/find-products",
      getProductsList: "/api/admin/product/get-all",
      newProductReview: "/api/admin/product/create",
      setProductReview: "/api/admin/product/update/{id}",
      getProductById: "/api/admin/product/get-one/{id}",
      uploadProductImage: "api/admin/product/upload-image",
      findAllProducts: "/api/admin/product/find-products",
      listBrand: "/api/admin/product/brand/list",

      /* Review review */
      getReviewList: "/api/admin/product/review/get-all",
      getReviewById: "/api/admin/product/review/get-one/{id}",
      setReviewById: "/api/admin/product/review/update/{id}",

      /* Review brand */
      getAllBrandReview: "/api/admin/product/brand/get-all",
      newBrandReview: " /api/admin/product/brand/create",
      setBrandReview: "/api/admin/product/brand/update/{id}",
      getBrandById: "/api/admin/product/brand/get-one/{id}",
    };

    this.baseURL = process.env.REACT_APP_API_URL;
    this.defaultAxios = axios.create({
      baseURL: this.baseURL,
      adapter: cache.adapter,
    });
    this.token = "";
    this.defaultAxios.interceptors.response.use(
      this.#handleResponse,
      this.#handleError
    );
  }

  /**
   * Redefine o cabeçalho de cada requisição
   * adicionando o token de autorização
   */
  #setAuthorizationHeader = () => {
    //this.token = JSON.parse(localStorage.getItem('token'));
    this.token = LocalStorageService.getItem("accessToken");
    // set token
    this.defaultAxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.token}`;
  };

  // Função para refazer todo o cache
  #resetCache = () => {
    cacheKey = generateCacheKey();
    const newCache = setupCache({
      maxAge: 15 * 60 * 1000,
      key: generateCacheKey(),
    });
    this.defaultAxios.defaults.adapter = newCache.adapter;
    console.log(
      "Cache resetado. Todas as próximas solicitações utilizarão o novo cache."
    );
  };
  /**
   * Realiza requisições do tipo get cancelando a requisição anterior
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async getWithCancel(suffix, params) {
    this.#setAuthorizationHeader();
    try {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Cancelando requisição anterior");
      }
      this.cancelTokenSource = axios.CancelToken.source();
      const response = await this.defaultAxios.get(suffix, {
        params,
        cancelToken: this.cancelTokenSource.token,
      });
      return response;
    } catch (err) {
      if (axios.isCancel(err)) {
        console.debug("Requisição cancelada:", err.message);
      } else if (verifyTokenExpired()) {
        localStorage.clear();
        window.location.reload();
      } else {
        console.error("Erro na requisição:", err);
        throw err;
      }
    }
  }
  /**
   * Realiza requisições do tipo get
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async get(suffix, params) {
    this.#setAuthorizationHeader();
    try {
      const response = await this.defaultAxios.get(suffix, { params });
      return response;
    } catch (err) {
      if (verifyTokenExpired()) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }

  /**
   * Realiza requisições do tipo get com porcentage
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async getPorcent(suffix, config) {
    this.#setAuthorizationHeader();
    return await this.defaultAxios.get(suffix, config);
  }

  /**
   * Realiza requisições do tipo post
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async post(suffix, parameters) {
    this.#setAuthorizationHeader();
    const iosBugFixHeader = {
      Headers: {
        "Content-Type": "text/plain",
      },
    };
    const response = await this.defaultAxios.post(
      suffix,
      parameters,
      iosBugFixHeader
    );
    this.#resetCache();
    return response;
  }

  /**
   * Realiza requisições do tipo patch
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async patch(suffix, parameters) {
    this.#setAuthorizationHeader();
    const response = await this.defaultAxios.patch(suffix, parameters);
    this.#resetCache();
    return response;
  }

  /**
   * Realiza requisições do tipo patch
   * @param {string} suffix string url
   * @param {object} parameters object parametros
   * @returns {Promise<any>} defaultAxios
   */
  async delete(suffix, parameters) {
    this.#setAuthorizationHeader();
    const response = await this.defaultAxios.delete(suffix, parameters);
    this.#resetCache();
    return response;
  }

  /**
   * Realiza o login na api e retorna dados do usuário.
   * @param {string} email - email do usuário
   * @param {string} password  - senha do usuário
   */
  async login(email, password, ipAddress) {
    try {
      const params = { email, password, ipAddress };
      const { signin } = this.routes;
      const { data } = await this.post(signin, params);
      return data;
    } catch (error) {
      return error;
    }
  }
  async loginStepTwo(email, code, ipAddress) {
    try {
      const params = { email, code, ipAddress };
      const { signinCode } = this.routes;
      const { data } = await this.post(signinCode, params);
      return data;
    } catch (error) {
      return error;
    }
  }
  async loginResendCode(email, ipAddress) {
    try {
      const params = { email, ipAddress };
      const { resendCode } = this.routes;
      const { data } = await this.post(resendCode, params);
      return data;
    } catch (error) {
      return error;
    }
  }
  async remainingAttempsCode(email) {
    try {
      const params = { email };
      const { remainingAttempsCode } = this.routes;
      const { data } = await this.post(remainingAttempsCode, params);
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Método responsável por tentar obter dados do usuário
   * logado:
   *
   * Obs: use-o somente se o token de acesso do usuário
   *
   * "accessToken" existir no armazenamento local.
   */
  async me() {
    try {
      const { me } = this.routes;
      const { data } = await this.get(me);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Obtém lista de usuários do bigcupom.
   * @returns {Array<object>|Error}
   */
  async getUsers(params, { signal }) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "id";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "id";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }
      if (params.fullName === "") {
        params.fullName = undefined;
      }
      if (params.phoneNumber === "") {
        params.phoneNumber = undefined;
      }
      if (params.email === "") {
        params.email = undefined;
      }
      if (params.cpf === "") {
        params.cpf = undefined;
      }

      const { listUsers } = this.routes;
      const data = await this.getWithCancel(listUsers, params, signal);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Busca um usuário por id
   * @param {number} id - id do usuário
   * @returns {Promise<any>|Error}
   */

  async getUserById(id) {
    try {
      const getUsersId = this.routes.getUsersId.replace("{id}", id);
      const data = await this.get(getUsersId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async upadateUserById(id, params) {
    try {
      const updateUsersId = this.routes.updateUsersId.replace("{id}", id);
      const data = await this.patch(updateUsersId, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async removeUserById(id, params) {
    try {
      const removeUserById = this.routes.removeUserById.replace("{id}", id);
      const data = await this.delete(removeUserById, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async upadateCpfUserById(id, params) {
    try {
      const validCpf = this.routes.validCpf.replace("{id}", id);
      const data = await this.patch(validCpf, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async updateUserStatusMission(id, params) {
    try {
      const updateUserStatusMission =
        this.routes.updateUserStatusMission.replace("{id}", id);
      const data = await this.patch(updateUserStatusMission, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getSmsByUserId(id) {
    try {
      const getSmsByUserId = this.routes.getSmsByUserId.replace("{id}", id);
      const data = await this.get(getSmsByUserId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getStoreById(id) {
    try {
      const getStoreId = this.routes.getStoreId.replace("{id}", id);
      const data = await this.get(getStoreId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getRewardById(id) {
    try {
      const getRewardId = this.routes.getRewardId.replace("{id}", id);
      const data = await this.get(getRewardId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getPreCouponByIdRekuten(id) {
    try {
      const getPreCouponByIdRekuten =
        this.routes.getPreCouponByIdRekuten.replace("{id}", id);
      const data = await this.get(getPreCouponByIdRekuten);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getCouponById(id) {
    try {
      const getCouponsId = this.routes.getCouponsId.replace("{id}", id);
      const data = await this.get(getCouponsId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getPreCouponById(id) {
    try {
      const getPreCouponId = this.routes.getPreCouponId.replace("{id}", id);
      const data = await this.get(getPreCouponId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async sendPreCoupon(id) {
    try {
      const sendPreCoupon = this.routes.sendPreCoupon.replace("{id}", id);
      const data = await this.get(sendPreCoupon);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getModuleById(id) {
    try {
      const getModuleId = this.routes.getModuleId.replace("{id}", id);
      const data = await this.get(getModuleId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getCategoryById(id) {
    try {
      const getCategoryId = this.routes.getCategoryId.replace("{id}", id);
      const data = await this.get(getCategoryId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setStoreActive(id) {
    try {
      const storeActive = this.routes.storeActive.replace("{id}", id);
      const data = await this.patch(storeActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setMissionActive(id) {
    try {
      const missionActive = this.routes.missionActive.replace("{id}", id);
      const data = await this.patch(missionActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async activePreCouponRekuten(id) {
    try {
      const activePreCouponRekuten = this.routes.activePreCouponRekuten.replace(
        "{id}",
        id
      );
      const data = await this.get(activePreCouponRekuten);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setGroupActive(id) {
    try {
      const groupActive = this.routes.groupActive.replace("{id}", id);
      const data = await this.post(groupActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setModuleActive(id) {
    try {
      const activeModule = this.routes.activeModule.replace("{id}", id);
      const data = await this.patch(activeModule);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setAdminUserActive(id) {
    try {
      const setadminUserActive = this.routes.setadminUserActive.replace(
        "{id}",
        id
      );
      const data = await this.patch(setadminUserActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setCouponActive(id) {
    try {
      const couponActive = this.routes.couponActive.replace("{id}", id);
      const data = await this.post(couponActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setBannerActive(id) {
    try {
      const bannerActive = this.routes.bannerActive.replace("{id}", id);
      const data = await this.patch(bannerActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getCarouselById(id) {
    try {
      const getCarouselId = this.routes.getCarouselId.replace("{id}", id);
      const data = await this.get(getCarouselId);
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Obtém lista de Slides do bigcupom.
   * @returns {Array<object> | Error}
   */
  async getCarousel() {
    try {
      const { getCarouselImages } = this.routes;
      const data = await this.get(getCarouselImages);
      return data;
    } catch (error) {
      return error;
    }
  }
  async setCarouselActive(id) {
    try {
      const carouselActive = this.routes.carouselActive.replace("{id}", id);
      const data = await this.patch(carouselActive);
      return data;
    } catch (error) {
      return error;
    }
  }

  async updateCarouselById(id, params) {
    try {
      const updateCarousel = this.routes.updateCarousel.replace("{id}", id);
      const data = await this.patch(updateCarousel, params);
      return data;
    } catch (error) {
      return error;
    }
  }
  async newCarousel(params) {
    try {
      const { newCarouselImage } = this.routes;
      const data = await this.post(newCarouselImage, params);
      return data;
    } catch (error) {
      return error;
    }
  }

  async orderCarousel(params) {
    try {
      const { orderCarousel } = this.routes;
      const data = await this.post(orderCarousel, params);
      return data;
    } catch (error) {
      return error;
    }
  }

  async setPasswordAdmin(params) {
    try {
      const setPassword = this.routes.setPassword;
      const data = await this.patch(setPassword, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setAccountAdmin(params) {
    try {
      const setadminUser = this.routes.setadminUser;
      const data = await this.patch(setadminUser, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setPrizeActived(id) {
    try {
      const prizeActive = this.routes.prizeActive.replace("{id}", id);
      const data = await this.patch(prizeActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setRewardActived(id) {
    try {
      const rewardActive = this.routes.rewardActive.replace("{id}", id);
      const data = await this.patch(rewardActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateStoreById(id, params) {
    try {
      const updateStore = this.routes.updateStore.replace("{id}", id);
      const data = await this.patch(updateStore, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateLandingPageById(id, params) {
    try {
      const updateLandingPage = this.routes.updateLandingPage.replace(
        "{id}",
        id
      );
      const data = await this.patch(updateLandingPage, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateRewardsById(id, params) {
    try {
      const updateRewardId = this.routes.updateRewardId.replace("{id}", id);
      const data = await this.patch(updateRewardId, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateMissionsById(id, params) {
    try {
      const updatemissionId = this.routes.updatemissionId.replace("{id}", id);
      const data = await this.patch(updatemissionId, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async orderMission(params) {
    try {
      const { orderMission } = this.routes;
      const data = await this.patch(orderMission, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async updateModuleById(id, params) {
    try {
      const updateModule = this.routes.updateModule.replace("{id}", id);
      const data = await this.patch(updateModule, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateuserAdminById(id, params) {
    try {
      const updateuserAdminId = this.routes.updateuserAdminId.replace(
        "{id}",
        id
      );
      const data = await this.patch(updateuserAdminId, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updatePrizeById(id, params) {
    try {
      const updatePrize = this.routes.updatePrize.replace("{id}", id);
      const data = await this.patch(updatePrize, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateReplacementStatus(id, params) {
    try {
      const updateReplacementStatus =
        this.routes.updateReplacementStatus.replace("{id}", id);
      const data = await this.post(updateReplacementStatus, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateNotificationStatusById(id) {
    try {
      const updateNotificationStatusById =
        this.routes.updateNotificationStatusById.replace("{id}", id);
      const data = await this.get(updateNotificationStatusById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateAllNotificationStatus() {
    try {
      const updateAllNotificationStatus =
        this.routes.updateAllNotificationStatus;
      const data = await this.get(updateAllNotificationStatus);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateCouponById(id, params) {
    try {
      const updateCoupon = this.routes.updateCoupon.replace("{id}", id);
      const data = await this.patch(updateCoupon, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateCategoryById(id, params) {
    try {
      const updateCategory = this.routes.updateCategory.replace("{id}", id);
      const data = await this.patch(updateCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getBannerById(id) {
    try {
      const getBannerId = this.routes.getBannerId.replace("{id}", id);
      const data = await this.get(getBannerId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getGroupById(id) {
    try {
      const getGroupId = this.routes.getGroupId.replace("{id}", id);
      const data = await this.get(getGroupId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getUsersAdminId(id) {
    try {
      const getusersAdminId = this.routes.getusersAdminId.replace("{id}", id);
      const data = await this.get(getusersAdminId);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Obtém lista de Slides do bigcupom.
   * @returns {Array<object> | Error}
   */
  async getBanner() {
    try {
      const { getBannerImages } = this.routes;
      const data = await this.get(getBannerImages);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getDashboard() {
    try {
      const { information } = this.routes;
      const data = await this.get(information);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getMenus(signal) {
    try {
      const { getMenus } = this.routes;
      const data = await this.getWithCancel(getMenus, {}, signal);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getGroups() {
    try {
      const { getallGroups } = this.routes;
      const data = await this.get(getallGroups);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getGroupActives() {
    try {
      const { getGroupActives } = this.routes;
      const data = await this.get(getGroupActives);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getUsersAdmin() {
    try {
      const { getusersAdmin } = this.routes;
      const data = await this.get(getusersAdmin);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  /**
   * Obtém lista de Numeros da Sorte do bigcupom.
   * @returns {Array<object> | Error}
   */
  async getPrize() {
    try {
      const { getPrize } = this.routes;
      const data = await this.get(getPrize);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getRewards() {
    try {
      const { getRewards } = this.routes;
      const data = await this.get(getRewards);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getRewardsMinu() {
    try {
      const { getRewardsMinu } = this.routes;
      const data = await this.get(getRewardsMinu);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getReplacements(params) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "statusExchangeId";
        params.dir = "asc";
      }
      if (params.sort === undefined) {
        params.sort = "statusExchangeId";
      }
      if (params.dir === undefined) {
        params.dir = "asc";
      }
      const { replacement } = this.routes;
      const data = await this.get(replacement, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReplacementDetailsById(id) {
    try {
      const getReplacementDetailsById =
        this.routes.getReplacementDetailsById.replace("{id}", id);
      const data = await this.get(getReplacementDetailsById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getPrizeById(id) {
    try {
      const PrizeById = this.routes.PrizeById.replace("{id}", id);
      const data = await this.get(PrizeById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getLandingPageById(id) {
    try {
      const getLandingPageById = this.routes.getLandingPageById.replace(
        "{id}",
        id
      );
      const data = await this.get(getLandingPageById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async deletePreCouponId(id) {
    try {
      const deletePreCouponId = this.routes.deletePreCouponId.replace(
        "{id}",
        id
      );
      const data = await this.delete(deletePreCouponId);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  /**
   * Obtém lista de lojas do bigcupom.
   * @returns {Array<object> | Error}
   */
  async getStore() {
    try {
      const { getStores } = this.routes;
      const data = await this.get(getStores);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getOrderMissions() {
    try {
      const { getOrderMissions } = this.routes;
      const data = await this.get(getOrderMissions);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getMissionsDependecy() {
    try {
      const { getMissionsDependecy } = this.routes;
      const data = await this.get(getMissionsDependecy);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getMission(params) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "createdAt";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "createdAt";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }

      const { getMissions } = this.routes;
      const data = await this.get(getMissions, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async storeDelete(id) {
    try {
      const storeDelete = this.routes.storeDelete.replace("{id}", id);
      const data = await this.delete(storeDelete);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async landingPageDelete(id) {
    try {
      const deleteLandingPage = this.routes.deleteLandingPage.replace(
        "{id}",
        id
      );
      const data = await this.delete(deleteLandingPage);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  /**
   * Obtém lista de cupons do bigcupom.
   * @returns {Array<object> | Error}
   */
  async getCategory() {
    try {
      const { getCategory } = this.routes;
      const data = await this.get(getCategory);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getCoupons() {
    try {
      const { getCoupons } = this.routes;
      const data = await this.get(getCoupons);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getRakutenCoupons() {
    try {
      const { getPreCouponRekuten } = this.routes;
      const data = await this.get(getPreCouponRekuten);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async syncRakutenCoupons(config) {
    try {
      const { syncPreCouponRekuten } = this.routes;
      return await this.getPorcent(syncPreCouponRekuten, config);
    } catch (error) {
      return error.response;
    }
  }
  async getRegisters(params) {
    try {
      const { getRegisters } = this.routes;
      const data = await this.get(getRegisters, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReportsLogin(params) {
    try {
      const { getReportsLogin } = this.routes;
      const data = await this.get(getReportsLogin, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReportsReward(params) {
    try {
      const { getReportsReward } = this.routes;
      const data = await this.get(getReportsReward, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReportsRewardData(params) {
    try {
      const { getReportsRewardData } = this.routes;
      const data = await this.get(getReportsRewardData, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReportsAffiliatesLogin(params) {
    try {
      const { getReportsAffiliatesLogin } = this.routes;
      const data = await this.get(getReportsAffiliatesLogin, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getAllPreCoupons() {
    try {
      const { generateAllCoupon } = this.routes;
      const data = await this.get(generateAllCoupon);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async statusGenerateCoupons() {
    try {
      const { statusGenerateCoupons } = this.routes;
      const data = await this.get(statusGenerateCoupons);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getModules() {
    try {
      const { getallModules } = this.routes;
      const data = await this.get(getallModules);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getAllLandingPage(params) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "createdAt";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "createdAt";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }

      const { getLandingPage } = this.routes;
      const data = await this.get(getLandingPage, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getNotifications() {
    try {
      const { getNotifications } = this.routes;
      const data = await this.get(getNotifications);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async newCoupon(params) {
    try {
      const { newCoupon } = this.routes;
      const data = await this.post(newCoupon, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newRakutenCoupon(params) {
    try {
      const { sendPreCouponRekuten } = this.routes;
      const data = await this.post(sendPreCouponRekuten, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newRewards(params) {
    try {
      const { newRewards } = this.routes;
      const data = await this.post(newRewards, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newMission(params) {
    try {
      const { newMission } = this.routes;
      const data = await this.post(newMission, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newModule(params) {
    try {
      const { newModule } = this.routes;
      const data = await this.post(newModule, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newusersAdmin(params) {
    try {
      const { newusersAdmin } = this.routes;
      const data = await this.post(newusersAdmin, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newLandingPage(params) {
    try {
      const { newLandingPage } = this.routes;
      const data = await this.post(newLandingPage, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageRewards(params) {
    try {
      const { uploadImageRewards } = this.routes;
      const data = await this.post(uploadImageRewards, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageCarousel(params) {
    try {
      const { uploadImageCarousel } = this.routes;
      let mode = params.get("mode");
      params.delete("mode");
      const data = await this.post(
        uploadImageCarousel + "?mode=" + mode,
        params
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageMissions(params) {
    try {
      const { uploadImageMissions } = this.routes;
      const data = await this.post(uploadImageMissions, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageStore(params) {
    try {
      const { uploadImageStore } = this.routes;
      let mode = params.get("mode");
      params.delete("mode");
      const data = await this.post(uploadImageStore + "?mode=" + mode, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageCoupon(params) {
    try {
      const { uploadImageCoupon } = this.routes;
      const data = await this.post(uploadImageCoupon, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageLandingPage(params) {
    try {
      const { uploadImageLandingPage } = this.routes;
      let mode = params.get("mode");
      params.delete("mode");
      const data = await this.post(
        uploadImageLandingPage + "?mode=" + mode,
        params
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async generateCoupon(params) {
    try {
      const { generateCoupon } = this.routes;
      const data = await this.post(generateCoupon, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async newCategory(params) {
    try {
      const { newCategory } = this.routes;
      const data = await this.post(newCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newPrize(params) {
    try {
      const { newPrize } = this.routes;
      const data = await this.post(newPrize, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateBannerById(id, params) {
    try {
      const updateBanner = this.routes.updateBanner.replace("{id}", id);
      const data = await this.patch(updateBanner, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newStore(params) {
    try {
      const { newStore } = this.routes;
      const data = await this.post(newStore, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newBanner(params) {
    try {
      const { newBannerImage } = this.routes;
      const data = await this.post(newBannerImage, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async orderCategory(params) {
    try {
      const { orderCategory } = this.routes;
      const data = await this.post(orderCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newGroup(params) {
    try {
      const { newGroup } = this.routes;
      const data = await this.post(newGroup, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateGroupById({ id, values }) {
    try {
      const updateGroupById = this.routes.updateGroupById.replace("{id}", id);
      const data = await this.patch(updateGroupById, values);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async orderModule(params) {
    try {
      const { orderModules } = this.routes;
      const data = await this.post(orderModules, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async newAffiliate(params) {
    try {
      const { newAffiliate } = this.routes;
      const data = await this.post(newAffiliate, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getAffiliates(params) {
    try {
      const { getAffiliates } = this.routes;
      const data = await this.get(getAffiliates);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateAffiliate(id, values) {
    try {
      const updateAffiliate = this.routes.updateAffiliate.replace("{id}", id);
      const data = await this.patch(updateAffiliate, values);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setAffiliateActive(id) {
    try {
      const affiliateActive = this.routes.affiliateActive.replace("{id}", id);
      const data = await this.patch(affiliateActive);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getGiftCard() {
    try {
      const { getGiftCard } = this.routes;
      const data = await this.get(getGiftCard);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async generateGiftCard(params) {
    try {
      const { generateGiftCard } = this.routes;
      const data = await this.post(generateGiftCard, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateSettings(params) {
    try {
      const updateSetting = this.routes.updateSetting;
      const data = await this.post(updateSetting, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getHotOffers() {
    try {
      const { listHotOffers } = this.routes;
      const data = await this.get(listHotOffers);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newHotOffers(params) {
    try {
      const { createHotOffers } = this.routes;
      const data = await this.post(createHotOffers, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updatenHotOffersById(id, params) {
    try {
      const updateHotOffers = this.routes.updateHotOffers.replace("{id}", id);
      const data = await this.patch(updateHotOffers, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getPostback() {
    try {
      const { getPostback } = this.routes;
      const data = await this.get(getPostback);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newPostback(params) {
    try {
      const { createPostback } = this.routes;
      const data = await this.post(createPostback, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updatePostBackById(id, params) {
    try {
      const updatePostBack = this.routes.updatePostBack.replace("{id}", id);
      const data = await this.patch(updatePostBack, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getSettings() {
    try {
      const { getSettings } = this.routes;
      const data = await this.get(getSettings);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateStartCron(params) {
    try {
      const { updateSetting } = this.routes;
      const data = await this.post(updateSetting, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  /* categoria review */
  async newReviewCategory(params) {
    try {
      const { newReviewCategory } = this.routes;
      const data = await this.post(newReviewCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newQuestionsByCategory(params) {
    try {
      const { newQuestionsByCategory } = this.routes;
      const data = await this.post(newQuestionsByCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getAllReviewCategory(params, { signal }) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "id";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "id";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }
      if (params.q === "") {
        params.q = undefined;
      }
      const { getAllReviewCategory } = this.routes;
      const data = await this.get(
        getAllReviewCategory,
        params
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getAllReviewCategoryList() {
    try {
      const { getAllReviewCategoryList } = this.routes;
      const data = await this.get(getAllReviewCategoryList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateQuestionsByCategory(id, params) {
    try {
      const setQuestionsByCategory = this.routes.setQuestionsByCategory.replace(
        "{id}",
        id
      );
      const data = await this.patch(setQuestionsByCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateReviewCategory(id, params) {
    try {
      const setReviewCategory = this.routes.setReviewCategory.replace(
        "{id}",
        id
      );
      const data = await this.patch(setReviewCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async deleteQuestionReviewCategory(id, params) {
    try {
      const deleteQuestionReviewCategory =
        this.routes.deleteQuestionReviewCategory.replace("{id}", id);
      const data = await this.delete(deleteQuestionReviewCategory);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async deleteAllQuestionReviewCategoryId(params) {
    try {
      const { deleteQuestionReviewCategoryId } = this.routes;
      const data = await this.post(deleteQuestionReviewCategoryId, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async deleteReviewCategory(id, params) {
    try {
      const deleteReviewCategory = this.routes.deleteReviewCategory.replace(
        "{id}",
        id
      );
      const data = await this.patch(deleteReviewCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadImageCategory(params) {
    try {
      const { uploadImageCategory } = this.routes;
      const data = await this.post(uploadImageCategory, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReviewCategoryById(id) {
    try {
      const getReviewCategoryById = this.routes.getReviewCategoryById.replace(
        "{id}",
        id
      );
      const data = await this.get(getReviewCategoryById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getQuestionsByCategory(id) {
    try {
      const getQuestionsByCategory = this.routes.getQuestionsByCategory.replace(
        "{id}",
        id
      );
      const data = await this.get(getQuestionsByCategory);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async findProductsMLList(params) {
    try {
      const { findProductsMLList } = this.routes;
      const data = await this.get(findProductsMLList, params);
      if (data?.status === 200) return data;
      else throw Error("Nenhum Produto encontrado");
    } catch (error) {
      throw error;
    }
  }
  async getProductsList(params, { signal }) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "id";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "id";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }
      if (params.q === "") {
        params.q = undefined;
      }
      const { getProductsList } = this.routes;
      const data = await this.get(getProductsList, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newProductReview(params) {
    try {
      const { newProductReview } = this.routes;
      const data = await this.post(newProductReview, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async updateProductReview(id, params) {
    try {
      const setProductReview = this.routes.setProductReview.replace("{id}", id);
      const data = await this.patch(setProductReview, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getProductById(id) {
    try {
      const getProductById = this.routes.getProductById.replace("{id}", id);
      const data = await this.get(getProductById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async uploadProductImage(params) {
    try {
      const { uploadProductImage } = this.routes;
      const data = await this.post(uploadProductImage, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async findAllBrand(params) {
    try {
      const { listBrand } = this.routes;
      const data = await this.get(listBrand, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReviewList(params, { signal }) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "id";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "id";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }
      if (params.q === "") {
        params.q = undefined;
      }
      if (params.brandId === "") {
        params.brandId = undefined;
      }
      if (params.categoryId === "") {
        params.categoryId = undefined;
      }

      const { getReviewList } = this.routes;
      const data = await this.get(getReviewList, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getReviewById(id) {
    try {
      const getReviewById = this.routes.getReviewById.replace("{id}", id);
      const data = await this.get(getReviewById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setReviewById(id, params) {
    try {
      const setReviewById = this.routes.setReviewById.replace("{id}", id);
      const data = await this.patch(setReviewById, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getAllBrandReview(params, { signal }) {
    try {
      if (params.sort === undefined && params.dir === undefined) {
        params.sort = "id";
        params.dir = "desc";
      }
      if (params.sort === undefined) {
        params.sort = "id";
      }
      if (params.dir === undefined) {
        params.dir = "desc";
      }
      if (params.q === "") {
        params.q = undefined;
      }

      const { getAllBrandReview } = this.routes;
      const data = await this.get(getAllBrandReview, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async newBrandReview(params) {
    try {
      const { newBrandReview } = this.routes;
      const data = await this.post(newBrandReview, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async setBrandReview(id, params) {
    try {
      const setBrandReview = this.routes.setBrandReview.replace("{id}", id);
      const data = await this.patch(setBrandReview, params);
      return data;
    } catch (error) {
      return error.response;
    }
  }
  async getBrandById(id) {
    try {
      const getBrandById = this.routes.getBrandById.replace("{id}", id);
      const data = await this.get(getBrandById);
      return data;
    } catch (error) {
      return error.response;
    }
  }
}
const ApiService = new ServiceApi();
export default ApiService;
