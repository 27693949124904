import lazyWithRetry from "helpers/lazyWithRetry";
import { Suspense } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import RoleBasedGuard from "./components/RoleBasedGuard";

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(
  lazyWithRetry(() => import("./pages/authentication/Login"))
);

// Dashboard pages

const Account = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/Account"))
);
const Analytics = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/Analytics"))
);

const StoreCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/store/StoreCreate"))
);
const StoreList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/store/StoreList"))
);

const MissionCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/mission/MissionCreate"))
);
const MissionList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/mission/MissionList"))
);
const MissionListOrder = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/mission/MissionListOrder"))
);

const CouponCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/coupon/CouponCreate"))
);
const CouponList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/coupon/CouponList"))
);
const HotOffersList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/hotOffers/HotOffersList"))
);
const HotOffersCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/hotOffers/HotOffersCreate"))
);

const QuizList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/quiz/QuizList"))
);
const QuizCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/quiz/QuizCreate"))
);


const RewardsCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/rewards/RewardsCreate"))
);
const RewardsList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/rewards/RewardsList"))
);
const RewardsGiftCardSync = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/rewards/RewardsGiftCardSync"))
);
const RewardsGiftCardList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/rewards/RewardsGiftCardList"))
);

const BannerCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/banner/BannerCreate"))
);
const BannerList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/banner/BannerList"))
);
const BannerDetails = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/banner/BannerDetails"))
);
const BannerEdit = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/banner/BannerEdit"))
);

const ModuleOrderList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/module/ModuleOrderList"))
);
const ModuleCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/module/ModuleCreate"))
);
const UserList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/UserList"))
);
const UserDetails = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/UserDetails"))
);

const UserAdminList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/userAdmin/UserAdminList"))
);
const UserAdminCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/userAdmin/UserAdminCreate"))
);
const CarouselList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/carousel/CarouselList"))
);
const CarouselEdit = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/carousel/CarouselEdit"))
);
const CarouselCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/carousel/CarouselCreate"))
);
const CarouselDetails = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/carousel/CarouselDetails"))
);
const CarouselOrderList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/carousel/CarouselOrderList"))
);
const Finance = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/Finance"))
);
const Overview = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/Overview"))
);

const ModuleList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/module/ModuleList"))
);
const ModuleDetails = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/module/ModuleDetails"))
);

const ExchangeList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/exchange/ExchangeList"))
);

const CategoryList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/category/CategoryList"))
);
const CategoryOrderList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/category/CategoryOrderList"))
);
const CategoryCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/category/CategoryCreate"))
);
const CategoryEdit = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/category/CategoryEdit"))
);

const GroupList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/group/GroupList"))
);
const GroupCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/group/GroupCreate"))
);
const GroupEdit = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/group/GroupEdit"))
);

const PrecouponAWIN = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/precoupon/PrecouponAWIN"))
);
const PrecouponAWINList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/precoupon/PrecouponAWINList"))
);

const Affiliates = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/affiliates/AffiliateList"))
);
const AffiliateCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/affiliates/AffilieateCreate"))
);
const ReportsLeads = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/reports/ReportsLeads"))
);
const ReportsLogin = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/reports/ReportsLogin"))
);
const ReportsLoginAffiliates = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/reports/ReportsLoginAffiliates"))
);
const ReportsRewards = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/reports/ReportsRewards"))
);
const GeneralList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/settings/general/GeneralList"))
);
const Short = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/short/Short"))
);
const LandingPageList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/landingpage/LandingPageList"))
);
const LandingPageCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/landingpage/LandingPageCreate"))
);
const ReviewProductCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/review/ReviewProductCreate"))
);
const ReviewCategoryCreate = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/review/ReviewCategoryCreate"))
);
const ReviewEdit = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/review/ReviewEdit"))
);
const ReviewList = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/review/ReviewList"))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazyWithRetry(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazyWithRetry(() => import("./pages/NotFound")));
const ServerError = Loadable(
  lazyWithRetry(() => import("./pages/ServerError"))
);
const PageOffline = Loadable(
  lazyWithRetry(() => import("./pages/PageOffline"))
);

// Other pages

const Home = Loadable(lazyWithRetry(() => import("./pages/Home")));
const routes = [
  {
    path: "singIn",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "group",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Grupos">
                <GroupList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Grupos">
                <GroupCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":groupId/edit",
            element: (
              <RoleBasedGuard role="UPDATE" route="Grupos">
                <GroupEdit />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "category",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Categorias">
                <CategoryList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "order",
            element: (
              <RoleBasedGuard role="UPDATE" route="Categorias">
                <CategoryOrderList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Categorias">
                <CategoryCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":categoryId/edit",
            element: (
              <RoleBasedGuard role="UPDATE" route="Categorias">
                <CategoryEdit />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Usuários">
                <UserList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":orderBy/:order",
            element: (
              <RoleBasedGuard role="READ" route="Usuários">
                <UserList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":userId",
            element: (
              <RoleBasedGuard role="READ" route="Usuários">
                <UserDetails />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "useradmin",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Administradores">
                <UserAdminList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Administradores">
                <UserAdminCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "affiliates",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Afiliados">
                <Affiliates />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Categorias">
                <AffiliateCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "landingpage",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Landing Page">
                <LandingPageList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":orderBy/:order",
            element: (
              <RoleBasedGuard role="READ" route="Landing Page">
                <LandingPageList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Landing Page">
                <LandingPageCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "review",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Avaliação">
                <ReviewList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":tab",
            element: (
              <RoleBasedGuard role="READ" route="Avaliação">
                <ReviewList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":orderBy/:order",
            element: (
              <RoleBasedGuard role="READ" route="Avaliação">
                <ReviewList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "product/new",
            element: (
              <RoleBasedGuard role="CREATE" route="Avaliação">
                <ReviewProductCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: "category/new",
            element: (
              <RoleBasedGuard role="CREATE" route="Avaliação">
                <ReviewCategoryCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "ReportsLeads",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Relatório de cadastros">
                <ReportsLeads />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "ReportsLogin",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Relatório de Login">
                <ReportsLogin />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "reportsAffiliatesLogin",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Logins por Afiliado">
                <ReportsLoginAffiliates />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "ReportsRewards",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Recompensa">
                <ReportsRewards />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "store",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Lojas">
                <StoreList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Lojas">
                <StoreCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "mission",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Missões">
                <MissionList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":orderBy/:order",
            element: (
              <RoleBasedGuard role="READ" route="Missões">
                <MissionList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Missões">
                <MissionCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: "order",
            element: (
              <RoleBasedGuard role="UPDATE" route="Missões">
                <MissionListOrder />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "banner",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Banner">
                <BannerList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":bannerId",
            element: (
              <RoleBasedGuard role="READ" route="Banner">
                <BannerDetails />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":bannerId/edit",
            element: (
              <RoleBasedGuard role="UPDATE" route="Banner">
                <BannerEdit />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Banner">
                <BannerCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "carousel",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Carrossel">
                <CarouselList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":carouselId",
            element: (
              <RoleBasedGuard role="READ" route="Carrossel">
                <CarouselDetails />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":carouselId/edit",
            element: (
              <RoleBasedGuard role="UPDATE" route="Carrossel">
                <CarouselEdit />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Carrossel">
                <CarouselCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: "order",
            element: (
              <RoleBasedGuard role="CREATE" route="Carrossel">
                <CarouselOrderList />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "modules",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Módulos">
                <ModuleList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":moduleId",
            element: (
              <RoleBasedGuard role="READ" route="Módulos">
                <ModuleDetails />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Módulos">
                <ModuleCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: "order",
            element: <ModuleOrderList />,
          },
        ],
      },
      {
        path: "coupon",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Cupons">
                <CouponList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "precoupon",
            element: (
              <RoleBasedGuard role="CREATE" route="Cupons">
                <PrecouponAWIN />
              </RoleBasedGuard>
            ),
          },
          {
            path: "precoupon/list",
            element: (
              <RoleBasedGuard role="CREATE" route="Cupons">
                <PrecouponAWINList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Cupons">
                <CouponCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "hotOffers",
        children: [
          {
            path: "",
            element: <HotOffersList />,
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Hot Ofertas">
                <HotOffersCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "quiz",
        children: [
          {
            path: "",
            element: <QuizList />,
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Hot Ofertas">
                <QuizCreate />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "exchange",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Troca">
                <ExchangeList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":orderBy/:order",
            element: (
              <RoleBasedGuard role="READ" route="Troca">
                <ExchangeList />
              </RoleBasedGuard>
            ),
          },
          {
            path: ":notificationOpen",
            element: (
              <RoleBasedGuard role="UPDATE" route="Troca">
                <ExchangeList />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "rewards",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard role="READ" route="Recompensas">
                <RewardsList />
              </RoleBasedGuard>
            ),
          },
          {
            path: "new",
            element: (
              <RoleBasedGuard role="CREATE" route="Recompensas">
                <RewardsCreate />
              </RoleBasedGuard>
            ),
          },
          {
            path: "gift_card",
            element: (
              <RoleBasedGuard role="CREATE" route="Recompensas">
                <RewardsGiftCardSync />
              </RoleBasedGuard>
            ),
          },
          {
            path: "gift_card/list",
            element: (
              <RoleBasedGuard role="CREATE" route="Recompensas">
                <RewardsGiftCardList />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "",
            element: <GeneralList />,
          },
          {
            path: ":configName",
            element: <GeneralList />,
          },
        ],
      },
      {
        path: "finance",
        element: <Finance />,
      },
      {
        path: "short",
        element: (
          <RoleBasedGuard role="READ" route="Encurtador">
            <Short />
          </RoleBasedGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "503",
        element: <PageOffline />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];
export default routes;
